import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => {
            return import('../views/Home.vue')
        },
        meta: {
            title: 'Instituto Burmann & Bastos',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça os tratamentos realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                },
                {
                    property: 'og:description',
                    content: 'Conheça os tratamentos realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                }
            ]
        }
    },
    {
        path: '/nossos-profissionais',
        name: 'Team',
        component: () => {
            return import('../views/Team.vue')
        },
        meta: {
            title: 'Nossos Profissionais',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça os profissionais do Instituto Burmann & Bastos'
                },
                {
                    property: 'og:description',
                    content: 'Conheça os profissionais do Instituto Burmann & Bastos'
                }
            ]
        }
    },
    {
        path: '/cardapio',
        name: 'Menu',
        component: () => {
            return import('../views/Menu.vue')
        },
        meta: {
            title: 'Cardápio',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça o cardápio de bebidas e lanches do Instituto Burmann & Bastos'
                },
                {
                    property: 'og:description',
                    content: 'Conheça o cardápio de bebidas e lanches do Instituto Burmann & Bastos'
                }
            ]
        }
    },
    {
        path: '/homens',
        name: 'Male',
        component: () => {
            return import('../views/Gender.vue')
        },
        props: {
          title: 'Homens',
          url: 'homens'
        },
        meta: {
            title: 'Tratamentos para Homens',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça os tratamentos para homens realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                },
                {
                    property: 'og:description',
                    content: 'Conheça os tratamentos para homens realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                }
            ]
        }
    },
    {
        path: '/mulheres',
        name: 'Female',
        component: () => {
            return import('../views/Gender.vue')
        },
        props: {
            title: 'Mulheres',
            url: 'mulheres'
        },
        meta: {
            title: 'Tratamentos para Mulheres',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça os tratamentos para mulheres realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                },
                {
                    property: 'og:description',
                    content: 'Conheça os tratamentos para mulheres realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                }
            ]
        }
    },
    {
        path: '/tecnologias',
        name: 'Tech',
        component: () => {
            return import('../views/Gender.vue')
        },
        props: {
            title: 'Tecnologias',
            url: 'tecnologias'
        },
        meta: {
            title: 'Tecnologias',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça as tecnologias utilizadas no Instituto Burmann & Bastos!'
                },
                {
                    property: 'og:description',
                    content: 'Conheça as tecnologias utilizadas no Instituto Burmann & Bastos!'
                }
            ]
        }
    },
    {
        path: '/homens/:area',
        name: 'MaleArea',
        component: () => {
            return import('../views/Area.vue')
        },
        props: {
            title: 'Homens',
            url: 'homens'
        },
        meta: {
            title: 'Tratamentos para Homens',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça os tratamentos para homens realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                },
                {
                    property: 'og:description',
                    content: 'Conheça os tratamentos para homens realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                }
            ]
        }
    },
    {
        path: '/mulheres/:area',
        name: 'FemaleArea',
        component: () => {
            return import('../views/Area.vue')
        },
        props: {
            title: 'Mulheres',
            url: 'mulheres'
        },
        meta: {
            title: 'Tratamentos para Mulheres',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça os tratamentos para mulheres realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                },
                {
                    property: 'og:description',
                    content: 'Conheça os tratamentos para mulheres realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                }
            ]
        }
    },
    {
        path: '/tecnologias/:area',
        name: 'TechArea',
        component: () => {
            return import('../views/Area.vue')
        },
        props: {
            title: 'Tecnologias',
            url: 'tecnologias'
        },
        meta: {
            title: 'Tecnologias',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça as tecnologias utilizadas no Instituto Burmann & Bastos!'
                },
                {
                    property: 'og:description',
                    content: 'Conheça as tecnologias utilizadas no Instituto Burmann & Bastos!'
                }
            ]
        }
    },
    {
        path: '/homens/:area/:treatment',
        name: 'MaleTreatment',
        component: () => {
            return import('../views/Treatment.vue')
        },
        props: {
            title: 'Homens',
            url: 'homens'
        },
        meta: {
            title: 'Tratamentos para Homens',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça os tratamentos para homens realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                },
                {
                    property: 'og:description',
                    content: 'Conheça os tratamentos para homens realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                }
            ]
        }
    },
    {
        path: '/mulheres/:area/:treatment',
        name: 'FemaleTreatment',
        component: () => {
            return import('../views/Treatment.vue')
        },
        props: {
            title: 'Mulheres',
            url: 'mulheres'
        },
        meta: {
            title: 'Tratamentos para Mulheres',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça os tratamentos para mulheres realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                },
                {
                    property: 'og:description',
                    content: 'Conheça os tratamentos para mulheres realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                }
            ]
        }
    },
    {
        path: '/tecnologias/:area/:treatment',
        name: 'TechTreatment',
        component: () => {
            return import('../views/Treatment.vue')
        },
        props: {
            title: 'Tecnologias',
            url: 'tecnologias'
        },
        meta: {
            title: 'Tecnologias',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça as tecnologias utilizadas no Instituto Burmann & Bastos!'
                },
                {
                    property: 'og:description',
                    content: 'Conheça as tecnologias utilizadas no Instituto Burmann & Bastos!'
                }
            ]
        }
    },
    {
        path: '/homens/:area/:treatment/:subtreatment',
        name: 'MaleSubtreatment',
        component: () => {
            return import('../views/Subtreatment.vue')
        },
        props: {
            title: 'Homens',
            url: 'homens'
        },
        meta: {
            title: 'Tratamentos para Homens',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça os tratamentos para homens realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                },
                {
                    property: 'og:description',
                    content: 'Conheça os tratamentos para homens realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                }
            ]
        }
    },
    {
        path: '/mulheres/:area/:treatment/:subtreatment',
        name: 'FemaleSubtreatment',
        component: () => {
            return import('../views/Subtreatment.vue')
        },
        props: {
            title: 'Mulheres',
            url: 'mulheres'
        },
        meta: {
            title: 'Tratamentos para Mulheres',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça os tratamentos para mulheres realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                },
                {
                    property: 'og:description',
                    content: 'Conheça os tratamentos para mulheres realizados pela equipe do Instituto Burmann & Bastos e descubra qual é o mais indicado para você!'
                }
            ]
        }
    },
    {
        path: '/tecnologias/:area/:treatment/:subtreatment',
        name: 'TechSubtreatment',
        component: () => {
            return import('../views/Subtreatment.vue')
        },
        props: {
            title: 'Tecnologias',
            url: 'tecnologias'
        },
        meta: {
            title: 'Tecnologias',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conheça as tecnologias utilizadas no Instituto Burmann & Bastos!'
                },
                {
                    property: 'og:description',
                    content: 'Conheça as tecnologias utilizadas no Instituto Burmann & Bastos!'
                }
            ]
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if (!nearestWithMeta) {
        return next();
    }

    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    }).forEach(tag => document.head.appendChild(tag));

    next();
});

export default router
