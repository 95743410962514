import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import Snotify from 'vue-snotify'

Vue.prototype.$mediaUrl = 'https://appadmin.institutoburmannebastos.com.br/media/';
Vue.config.productionTip = false;
Vue.use(Snotify);

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test( userAgent );
};
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
if (isIos() && !isInStandaloneMode()) {
  Vue.prototype.$showPWAInstallMessage = true;
}

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app');
