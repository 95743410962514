<template>
  <div id="app">
    <div class="bg">
      <img src="/img/bg-n.jpg" alt="">
    </div>
    <div class="nav-btns" v-if="$route.path !== '/'">
      <button class="nav-btn back" v-on:click="$router.back()">
        <img src="/img/arrow.svg" alt="Seta para esquerda, voltar">
      </button>
      <button class="nav-btn reset" v-on:click="$router.push('/')" v-if="$route.path !== '/cardapio'">
        <img src="/img/repeat.svg" alt="Símbolo de reiniciar">
      </button>
    </div>
    <transition name="page" mode="out-in">
      <router-view/>
    </transition>
    <vue-snotify/>
  </div>
</template>

<script>
  export default {
    name: 'App',
    components: {}
  }
</script>

<style lang="scss">
  @import "~vue-snotify/styles/material";

  #app {
    background-color: #FFFFFF;
    height: 100%;
  }

  .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $main-color;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .page-enter-active, .page-leave-active {
    transition: opacity 0.5s, transform 0.5s;
  }

  .page-enter, .page-leave-to {
    opacity: 0;
    transform: translateY(-30%);
  }

  .nav-btns {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    padding: 10px;

    .nav-btn {
      background-color: rgba(255, 255, 255, 0.9);
      border: none;
      border-radius: 10px;
      cursor: pointer;
      padding: 10px;
      transition: box-shadow 0.3s;

      &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      }

      img {
        height: 30px;
        width: auto;
      }
    }

    .reset {
      margin-left: auto;
    }
  }
</style>
